import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {AlertsService, CLToastType} from '@clavisco/alerts';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {DataStorageService} from '../services/data-storage.service';
import {GlobalService} from '../services/global.service';
import {MenuService} from '../services/menu.service';
import {IMenuItem} from "../interfaces/menu";
import {Repository} from "@clavisco/core";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor (
    public auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    private alertService: AlertsService,
    private dataStorage: DataStorageService,
    private globalService: GlobalService,
    private menuService: MenuService,
  ) {
  }

  /**
   * Determines if the route can be activated based on the user's authentication status and access permissions.
   *
   * @param route - The route that is being activated, represented by an `ActivatedRouteSnapshot`.
   * @param state - The router state, represented by a `RouterStateSnapshot`.
   * @returns An `Observable<boolean | UrlTree>`, `Promise<boolean | UrlTree>`, `boolean`, or `UrlTree` indicating if the route can be activated.
   *
   * The method performs the following checks:
   * - Verifies if the user is authenticated.
   * - Checks if the user has access to the requested route based on permissions.
   * - Redirects to the Home or Login page if access is denied or if the user is not authenticated.
   * - Displays toast notifications for access issues and authentication errors.
   */
  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path: string = state.url.substring(1);
    if (this.auth.IsActhenticated()) {
      if (this.menuService.GetLocalMenu()) {
        const node = this.globalService.FindNodeByRoute(this.menuService.GetLocalMenu(), path);
        if (node) {
          if (!this.dataStorage.GetUserAccess().includes(node.Permission) && path != 'Home' && !this.dataStorage.GetUserAccess().includes('M_Administration')) {
            this.alertService.Toast({
              message: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
              type: CLToastType.ERROR,
              duration: 2000
            });
            this.router.navigate(['/Home']);
            return false;
          }
        } else {
          this.alertService.Toast({
            message: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
            type: CLToastType.ERROR,
            duration: 2000
          });
          this.router.navigate(['/Home']);
          return false;
        }
      }
      const actualPath: string = this.GetRouteWithoutParams(route);
      this.menuService.SetCurrentPageTitle(path);
      return true;
    } else {
      this.alertService.Toast({message: 'No está autenticado, por favor inicie sesión', type: CLToastType.ERROR});
      this.auth.Logout();
      this.router.navigate(['/Login']);
      return false;
    }
  }

  /**
   * Extracts the route path without parameters from the `ActivatedRouteSnapshot`.
   *
   * @param route - The route snapshot containing the URL segments.
   * @returns The route path as a string, or an empty string if no segments are present.
   */
  private GetRouteWithoutParams (route: ActivatedRouteSnapshot): string {
    const urlSegments: string[] = route.url.map(segment => segment.path);
    if (urlSegments.length > 0) {
      return urlSegments[0];
    }
    return '';
  }

}
