import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSidenavModule} from "@angular/material/sidenav";
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatCardModule} from "@angular/material/card";
import {MatDialogModule} from "@angular/material/dialog";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TableModule} from "@clavisco/table";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSelectModule} from "@angular/material/select";
import {UploadFilesComponent} from "./upload-files/upload-files.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {NotificationCenterModule} from "@clavisco/notification-center";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
    TableModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSelectModule,
    NotificationCenterModule
  ],
  exports: [
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
    TableModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSelectModule,
    UploadFilesComponent,
    MatSlideToggleModule,
    NotificationCenterModule
  ],
  declarations: [
    UploadFilesComponent
  ],
  providers: []
})
export class SharedModule { }
