import {Component, ElementRef, Inject, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OverlayService} from "@clavisco/overlay";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {catchError, finalize, tap} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IUser} from "../../../../interfaces/user";
import {UserService} from "../../../../services/user.service";
import {DataStorageService} from "../../../../services/data-storage.service";
import {GlobalService} from "../../../../services/global.service";


@Component({
  selector: 'app-modal-users',
  templateUrl: './modal-users.component.html',
  styleUrls: ['./modal-users.component.scss']
})
export class ModalUsersComponent implements OnInit {
  @ViewChild('InputPassword') InputPassword!: ElementRef;

  defaultPicture: string = '../../../../assets/img/user.png';
  profilePicture: string = this.defaultPicture;

  formUser!: UntypedFormGroup;
  action: string = '';
  hidePassword: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUser,
    private dialogRef: MatDialogRef<ModalUsersComponent>,
    private clBlockUi: OverlayService,
    private alertService: AlertsService,
    public formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private dataStorage: DataStorageService,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit(): void {
    this.action = this.data ? 'Actualizar' : 'Crear';
    this.OnLoad();
  }

  /**
   * Initializes the user form with default or `data` values.
   * - Creates a form using `formBuilder` with necessary fields.
   * - Sets a profile picture if available in `data`.
   *
   * Configures form controls with initial values, including validations for fields like name, email, and password.
   */
  OnLoad(): void {
    this.formUser = this.formBuilder.group({
      Id: [this.data ? this.data.Id : 0],
      ProfilePicture: [this.data ? this.data.ProfilePicture : null],
      Name: [this.data ? this.data.Name : '', Validators.required],
      LastName: [this.data ? this.data.LastName : '', Validators.required],
      Email: [this.data ? this.data.Email : '', [Validators.required, Validators.email]],
      Password: ['', this.data ? null : [Validators.required, Validators.minLength(8)]],
      CreatedDate: [this.data ? this.data.CreatedDate : new Date()],
      CreatedBy: [this.data ? this.data.CreatedBy : ''],
      UpdatedBy: [''],
      IsActive: [this.data ? this.data.IsActive : true]
    });
    if (this.data?.ProfilePicture) {
      this.profilePicture = this.data.ProfilePicture;
    }
  }

  /**
   * Saves the user based on the presence of existing data.
   * - If `data` is present, updates the existing user using `PatchUser`.
   * - If `data` is not present, creates a new user using `PostUser`.
   *
   * Retrieves the form data and determines the appropriate action.
   */
  SaveUser(): void {
    const user: IUser = this.formUser.getRawValue();
    if (this.data) {
      this.PatchUser(user);
    } else {
      this.PostUser(user);
    }
  }

  /**
   * Creates a new user and manages the loading state.
   * - Displays a loading message while creating the user.
   * - Shows a success message if the user is created successfully.
   * - Closes the dialog if the user is created successfully.
   *
   * @param usr - The user to be created.
   */
  PostUser(usr: IUser): void {
    this.clBlockUi.OnPost("Creando Usuario")
    this.userService.Post(usr)
      .pipe(finalize((): void => {
        this.clBlockUi.Drop();
      }), catchError((err) => {
        return [];
      })).subscribe(
      (data: ICLResponse<IUser>): void => {
        if (data.Data != null) {
          this.alertService.Toast({
            message: `Usuario ${data.Data.Name} creado correctamente`,
            type: CLToastType.SUCCESS
          })
          this.dialogRef.close(true)
        }
      }
    );
  }

  /**
   * Handles input changes to adjust form validation.
   * - If `data` is present and the new value is not empty, sets a minimum length validation for the `Password` field.
   *
   * @param newValue - The new input value.
   */
  OnInputChange(newValue: string): void {
    if (this.data && newValue != '') {
      this.formUser.get('Password')?.setValidators(Validators.minLength(8));
    }
  }

  /**
   * Updates a user and manages the loading state.
   * - Displays a loading message while updating the user.
   * - Updates the global avatar if the user's profile matches the current user.
   * - Shows a success message if the update is successful.
   * - Closes the dialog if the update is successful.
   *
   * @param usr - The user to be updated.
   */
  PatchUser(usr: IUser): void {
    this.clBlockUi.OnPost("Actualizando Usuario");
    this.userService.Patch(usr)
      .pipe(finalize((): void => {
        this.clBlockUi.Drop();
      }), catchError((err) => {
        return [];
      })).subscribe((data: ICLResponse<IUser>): void => {
      if (data.Data != null) {
        const user: number = this.dataStorage.GetUserId()
        if (usr.ProfilePicture && usr.Id === user) {
          this.globalService.UserAvatar.next(usr.ProfilePicture);
        }
        this.alertService.Toast({
          message: `Usuario ${data.Data.Name} actualizado correctamente`,
          type: CLToastType.SUCCESS
        });
        this.dialogRef.close(true);
      }
    });
  }

  /**
   * Toggles the visibility of the password.
   * - Switches the `hidePassword` state between true and false.
   */
  TogglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }

  /**
   * Converts a file or blob to a Base64 string.
   * - Uses `FileReader` to read and convert the file.
   * - Returns a promise that resolves with the Base64 string of the file.
   *
   * @param file - The file or blob to be converted.
   * @returns A promise that resolves with the Base64 string of the file.
   */
  FileToBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject): void => {
      const reader: FileReader = new FileReader();
      reader.onload = (): void => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(file);
      reader.onerror = reject;
    });

  /**
   * Clears the profile picture from the form and resets it to the default picture.
   * - Sets the `ProfilePicture` field in the form to `null`.
   * - Resets the `profilePicture` property to the default picture.
   */
  ClearPicture(): void {
    this.formUser.get('ProfilePicture')?.setValue(null);
    this.profilePicture = this.defaultPicture;
  }

  /**
   * Handles file selection and updates the profile picture and form.
   * - Converts selected files to Base64 format and assigns them to the `ProfilePicture` field in the form.
   * - Updates the `profilePicture` property with the selected image.
   *
   * @param event - The file selection event.
   */
  OnSelectFile = async (event: any): Promise<void> => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    let b64;
    await Promise.all(
      [].map.call(target.files, async (file: File) => {
        const type: string[] = file.name.split('.');
        b64 = await this.FileToBase64(file);
        this.formUser.get('ProfilePicture')?.setValue(b64);
        if (event.target.files && event.target.files[0]) {
          var reader: FileReader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = (event) => {
            if (event.target?.result) {
              this.profilePicture = event.target.result.toString();
            }
          }
        }
      })
    );
  }

}
