import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {IFile} from "../../interfaces/file";


@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})

export class UploadFilesComponent implements OnInit {
  @Output()
  eventSelectedFiles = new EventEmitter<IFile[]>();

  @Input()
  listExtensions!: string[];
  @Input()
  MultipleFiles!: boolean

  files: IFile[] = [];
  listFiles: string[] = [];

  constructor(
    private alertService: AlertsService
  ) {
  }


  ngOnInit() {

  }

  /**
   * Deletes a file from the lists of files and updates the selection.
   *
   * @param {number} index - The index of the file to delete from the lists.
   */
  DeleteFile(index: number) {
    this.listFiles.splice(index, 1)
    this.files.splice(index, 1)
    this.SelectedFile(this.files);
  }

  /**
   * Validates the file extension against a list of allowed extensions.
   *
   * @param {string} type - The file extension to validate.
   * @returns {boolean} - Returns true if the extension is allowed, otherwise false.
   */
  ValidateExtensions(type:string): boolean{
    if (this.listExtensions.filter(x => x === type).length === 0) {
      this.alertService.Toast({ message: 'No se permite la extensión "' + type+'"', type: CLToastType.ERROR })
      return false;
    }
    return true;
  }

  /**
   * Converts a file or blob to a Base64 encoded string.
   *
   * @param {File | Blob} file - The file or blob to convert.
   * @returns {Promise<string>} - A promise that resolves to the Base64 encoded string of the file.
   */
  FileToBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(file);
      reader.onerror = reject;
    });

  /**
   * Handles the file selection event and processes the selected files.
   *
   * @param {Event} e - The file selection event.
   */
  OnSelectFiles = async (e: Event) => {
    if(this.MultipleFiles || this.listFiles.length > 0){
      return this.alertService.Toast({ message: 'Solo se puede cargar un archivo', type: CLToastType.ERROR });
    }
    const target = e.target as HTMLInputElement;
    let b64;
    await Promise.all(
      [].map.call(target.files, async (file: File) => {
        const type = file.name.split('.');
        if(!this.ValidateExtensions(type[type.length-1])) return
        if (this.listFiles.filter(x => x == file.name).length > 0) return this.alertService.Toast({ message: 'El archivo ya existe', type: CLToastType.ERROR });
        b64 = (await this.FileToBase64(file)).split(',');
        this.listFiles.push(file.name)
        this.files.push({
          FileName: file.name,
          Base64: b64[b64.length - 1],
          FileExtension: type[type.length-1],
          BlobType: ''
        });
      })
    );

    this.SelectedFile(this.files);

  }

  /**
   * Emits the selected files event.
   *
   * @param {IFile[]} files - The list of selected files.
   */
  SelectedFile(files: IFile[]){
    this.eventSelectedFiles.emit(files)
  }

}
