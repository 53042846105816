import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {PagesComponent} from "./pages/pages.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {SharedModule} from "./shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { LoginModule } from '@clavisco/login';
import { MenuModule } from '@clavisco/menu';
import {LoginContainerComponent} from "./pages/login/login-container.component";
import {AppInterceptor} from "./interceptors/app-interceptor";
import {ActionCenterPushInterceptor} from "./interceptors/action-center-push.interceptor";
import {OverlayModule, OverlayService} from "@clavisco/overlay";
import {Miscellaneous} from "@clavisco/core";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";
import {environment} from "../environments/environment";


@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    LoginContainerComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    MenuModule,
    OverlayModule,
  ],
  providers: [
    { provide: 'OverlayService', useClass: OverlayService },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ActionCenterPushInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.PagedRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recatpchaSiteKey
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
