import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {Subject, Subscription, tap} from "rxjs";
import {IActionButton} from "../interfaces/TMP/action-button";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private readonly actionButtonClicked$: Subject<IActionButton>;

  constructor(private router: Router) {
    this.actionButtonClicked$ = new Subject<IActionButton>();
  }

  /**
   * Gets the current route segment from the router's URL.
   *
   * @returns {string} The current route segment, which is the part of the URL before any query parameters. Defaults to '/' if the URL is empty.
   */
  GetCurrentRouteSegment(): string {
    return this.router.url.split('?').shift() || '/';
  }

  /**
   * Subscribes to the action button click events and executes the provided function when an action button is clicked.
   *
   * @param {_func} _func - A callback function to execute when an action button is clicked. The function receives the clicked action button as an argument.
   * @returns {Subscription} The subscription object that can be used to unsubscribe from the action button click events.
   */
  OnActionButtonClicked(_func: (_actionButton: IActionButton) => void): Subscription {
    return this.actionButtonClicked$.asObservable()
      .pipe(
        tap(_func)
      )
      .subscribe();
  }

  /**
   * Defines the HTTP headers for request descriptions.
   *
   * @param {_descriptions} _descriptions - An object containing descriptions for error, success, and optional detail messages.
   * @param {_headers} [_headers] - Optional existing HTTP headers to modify. If not provided, a new HttpHeaders object is created.
   * @returns {HttpHeaders} The HTTP headers with the specified descriptions set.
   */
  DefineDescriptionHeader(_descriptions: { OnError: string, OnSuccess?: string, OnDetail?: string}, _headers: HttpHeaders | null | undefined = undefined): HttpHeaders
  {
    if(_headers)
    {
      return _headers.set('Cl-Request-Error-Description', _descriptions.OnError).set('Cl-Request-Success-Description', _descriptions.OnSuccess??"").set('Cl-Request-Detail-Description', _descriptions.OnDetail??"");
    }
    return new HttpHeaders().set('Cl-Request-Error-Description', _descriptions.OnError).set('Cl-Request-Success-Description', _descriptions.OnSuccess??"").set('Cl-Request-Detail-Description', _descriptions.OnDetail??"");
  }
}
