import {Injectable} from '@angular/core';
import {tap, catchError, switchMap} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {IMenuItem} from "../interfaces/menu";
import {AssetsService} from "./assets.service";
import {MenuService} from "./menu.service";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public currentPageTitle: Subject<string>;
  public currentModuleTitle: Subject<string>;
  public UserAvatar: Subject<string>;
  public src: string = "";

  public tempMenu: IMenuItem[] = [];

  constructor(
    private assetService: AssetsService,
    private menuService: MenuService
  ) {
    this.currentPageTitle = new Subject<string>();
    this.currentModuleTitle = new Subject<string>();
    this.UserAvatar = new Subject<string>();
  }

  /**
   * Retrieves the description of the current view based on the given path.
   * @param {string} path - The route path of the view.
   * @returns {string} The description of the current view.
   */
  GetCurrentViewName(path: string): string {
    return this.FindDescriptionRecursively(this.tempMenu, path);
  }

  /**
   * Recursively searches for the description of a menu item based on the route path.
   * @param {IMenuItem[]} menu - The menu items to search through.
   * @param {string} path - The route path of the menu item.
   * @returns {string} The description of the menu item, or an empty string if not found.
   */
  FindDescriptionRecursively(menu: IMenuItem[], path: string): string {
    for (const node of menu) {
      if (node.Route === path) {
        return node.Description;
      }
      if (node.Nodes && node.Nodes.length > 0) {
        const description = this.FindDescriptionRecursively(node.Nodes, path);
        if (description) {
          return description;
        }
      }
    }
    return "";
  }

  /**
   * Sets the menu options by retrieving data from the menu service and updating the current page title.
   * @param {string} path - The route path to set the current view title.
   */
  SetMenuOptions(path: string): void {
    this.menuService.Get().pipe(
      tap((data: ICLResponse<IMenuItem[]>): void => {
        if (data.Data) {
          const filterMenu: IMenuItem[] = data.Data.filter(x => x.Visible)
          this.assetService.Data.menu = filterMenu;
          this.assetService.Menu.next(filterMenu);
          this.tempMenu = filterMenu;
          this.currentPageTitle.next(this.GetCurrentViewName(path))
        }
      }),
      catchError(async () => this.SetMenuOptions(path))
    ).subscribe();
  }

  /**
   * Recursively searches for a menu item based on the route path.
   * @param {IMenuItem[]} menu - The menu items to search through.
   * @param {string} path - The route path of the menu item.
   * @returns {IMenuItem | null} The menu item if found, or null if not found.
   */
  FindNodeByRoute(menu: IMenuItem[], path: string): IMenuItem | null {
    for (const node of menu) {
      if (node.Route === path && node.Route != 'Admin') {
        return node;
      }
      if (node.Nodes && node.Nodes.length > 0) {
        const foundNode = this.FindNodeByRoute(node.Nodes, path);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null; // Devuelve null si no se encuentra el nodo
  }

  /**
   * Formats a date into a specified format.
   * Date Formatting Function (yyyy-MM-dd)
   * @param {string | Date} _date - The date to format. Can be a date string or a Date object.
   * @param {string} type - The format type: 'corta' for short date or other for full date-time.
   * @returns {string} The formatted date string.
   */
  FormatDate(_date: string | Date, type: string): string {
    if (type === 'corta') {
      if (_date) {
        return formatDate(_date, 'yyyy-MM-dd', 'en');
      } else {
        return formatDate(new Date(), 'yyyy-MM-dd', 'en');
      }
    } else {
      return formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss', 'en');
    }
  }

  /**
   * Converts a date to the Costa Rica time zone.
   * @param date - The date to convert.
   */
  ConvertDateToCRDate(date: Date): Date {
    return new Date(date.toLocaleString('en-US', {
      timeZone: 'America/Costa_Rica'
    }));
  }

}

