import {Injectable} from "@angular/core";
import {DataStorageService} from "./data-storage.service";
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  constructor(
    private dataStorageService: DataStorageService,
    private router: Router,
  ) {
  }

  /**
   * Checks if the user is authenticated based on the current session's token.
   *
   * @returns {boolean} True if the user is authenticated, otherwise false.
   */
  public IsActhenticated(): boolean {
    if (this.dataStorageService.GetStorage('CurrentSession') === null) return false;
    const TOKEN = JSON.parse(atob(this.dataStorageService.GetStorage('CurrentSession')!));
    if (TOKEN) {
      const expires: Date = new Date(TOKEN.expires);
      const utcNow: Date = this.GetUTCNow();
      const issued: Date = new Date(TOKEN.issued);
      const startOfToday: Date = new Date(utcNow.getFullYear(), utcNow.getMonth(), utcNow.getDate());
      if (utcNow <= expires && issued >= startOfToday) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets the current date and time in UTC.
   *
   * @returns {Date} The current date and time in UTC.
   */
  private GetUTCNow(): Date {
    const dateNow: Date = new Date();
    return new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), dateNow.getUTCHours(), dateNow.getUTCMinutes(), dateNow.getUTCSeconds());
  }

  /**
   * Logs out the user by deleting the current session from storage.
   */
  Logout(): void {
    try {
      this.dataStorageService.DeleteStorage('CurrentSession');
    } catch (error) {
    }
  }
}
