import { Injectable } from '@angular/core';
import {IToken} from "../interfaces/token";
import { Repository } from "@clavisco/core"

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  private localStorageS;


  constructor() {
    this.localStorageS = localStorage;
  }

  /**
   * Retrieves the email of the current user from the stored session token.
   * @returns {string} The email of the current user.
   */
  GetUserEmail():string{
    const TOKEN: IToken = JSON.parse(atob(this.GetStorage('CurrentSession')!));
      return TOKEN.userName;
  }

  /**
   * Retrieves the user ID of the current user from the stored session token.
   * @returns {number} The user ID of the current user. Returns 0 if no session is found.
   */
  GetUserId():number{
    let session:string | null = this.GetStorage('CurrentSession');

    if(!session) return 0;
    const TOKEN: IToken = JSON.parse(atob(session!));
    return TOKEN.userId;
  }


  /**
   * Retrieves the access permissions of the current user from the stored session token.
   * @returns {string[]} An array of permissions assigned to the current user.
   */
  GetUserAccess(): string[] {
    const TOKEN: IToken = JSON.parse(atob(this.GetStorage('CurrentSession')!));
    if(!TOKEN) return []
    const permiss :string[] = JSON.parse(TOKEN.permissions)
    return permiss;
  }

  /**
   * Deletes an item from local storage.
   * @param {string} name - The name of the item to be deleted.
   */
  DeleteStorage(name: string): void {
    Repository.Behavior.DeleteStorageObject(name);
  }

  /**
   * Retrieves an item from local storage.
   * @param {string} name - The name of the item to be retrieved.
   * @returns {string | null} The value of the item, or null if not found.
   */
  GetStorage(name: string): string | null {
    return this.localStorageS.getItem(name) ?? null;
  }


}
