import {PagesComponent} from "./pages/pages.component";
import {Route, RouterModule, Routes} from "@angular/router";
import {ModuleWithProviders} from "@angular/core";
import {AuthGuard} from "./guards/auth.guard";

export const routes: Routes = [
  {
    path: 'Login',
    loadChildren: () => import('./pages/login/login-container.module').then(m => m.LoginContainerModule)},
  {
    path: '',
    component: PagesComponent,
    children: [
      {path: 'Home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule ), canActivate: [AuthGuard]},
      {path: 'Home/:Option', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule ), canActivate: [AuthGuard]},
      {path: 'Admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule, ),canActivate: [AuthGuard] },
      {path: 'TCM', loadChildren: () => import('./pages/TCM/tcm.module').then(m => m.TCMModule, ), canActivate: [AuthGuard] },
      {path: 'TMP', loadChildren: () => import('./pages/TMP/tmp.module').then(m => m.TMPModule, ), canActivate: [AuthGuard] },
      {path: 'ACB', loadChildren: () => import('./pages/ACB/acb.module').then(m => m.ACBModule, ), canActivate: [AuthGuard] },
      {
        path: '',
        redirectTo: '/Login',
        pathMatch: 'full'
      },
    ]
  },
];

export const AppRoutingModule: ModuleWithProviders<Route> = RouterModule.forRoot(routes, {
  useHash: true,
  onSameUrlNavigation: 'reload'
});
