import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpHeaders
} from '@angular/common/http';
import {catchError, filter, Observable, tap, throwError} from 'rxjs';
import {CLNotificationType, NotificationCenterService} from "@clavisco/notification-center";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable()
export class ActionCenterPushInterceptor implements HttpInterceptor {

  constructor(private notificationCenterService: NotificationCenterService) {}

  /**
   * Intercepts HTTP requests and responses to handle notifications based on request and response details.
   *
   * This method performs the following actions:
   * - Allows requests with URLs containing 'token' to pass through unmodified.
   * - For non-'GET' requests, it checks for a success description in the request headers and, if present, pushes a success notification.
   * - Handles errors by pushing an error notification based on the error details.
   *
   * @param request - The outgoing HTTP request being intercepted.
   * @param next - The HTTP handler that will handle the request after interception.
   * @returns An observable of the HTTP event that completes with the HTTP response or error.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes('token')) return next.handle(request);
    return next.handle(request).pipe(
      filter(response => response instanceof HttpResponse),
      tap(response => {
        if(request.method !== 'GET' && (request.headers.get('Cl-Request-Success-Description') && request.headers.get('Cl-Request-Success-Description') != "")){
          this.notificationCenterService.Push({
            type: CLNotificationType.SUCCESS,
            priority: 'low',
            title: request.headers.get('Cl-Request-Success-Description') || this.CutURL(request.url),
            message: (response['headers' as keyof object] as HttpHeaders)?.get('cl-message') || (response["body" as keyof object] as ICLResponse<any>)?.Message || ''
          });
        }
      }),
      catchError((error: any) => {
        this.notificationCenterService.Push({
          type: CLNotificationType.ERROR,
          priority: 'high',
          title:  request.headers.get('Cl-Request-Error-Description') || this.CutURL(request.url),
          message: (error['headers' as keyof object] as HttpHeaders)?.get('cl-message') ?? error.error.Message ?? "Error"
        });
        return throwError(() => error);
      })
    )
  }

  /**
   * Extracts a simplified version of the URL from the provided URL string.
   *
   * @param _url - The URL string from which to extract a simplified path.
   * @returns A substring of the URL starting from 'api' to the end of the URL or up to a query string, if present.
   */
  private CutURL(_url:string): string
  {
    let startIndex = _url.indexOf('api');
    let endIndex = _url.indexOf("?");

    return _url.substring(startIndex, endIndex === -1 ? _url.length : endIndex);
  }
}
