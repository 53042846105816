import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {IMenuNode} from "@clavisco/menu";
import {HttpClient} from "@angular/common/http";
import {IMenuItem} from "../interfaces/menu";
import {ICountryCode} from "../interfaces/CountryCode";
import {ICalculateType} from '../interfaces/CalculateType';

export class Data {
  constructor(public menu: IMenuItem[],
              public currentUser: string) { }
}

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  public Menu: Subject<IMenuItem[]>;
  public Data: Data = new Data(
    [], // menuList
    '', // current user
  )

  public url: string = "assets/data/";
  constructor(public http: HttpClient) {
    this.Menu = new Subject<IMenuItem[]>();
  }

  /**
   * Retrieves a list of country codes.
   *
   * @returns {Observable<ICountryCode[]>} An observable containing the array of country codes.
   */
  public GetCountriesCodes(): Observable<ICountryCode[]> {
    return this.http.get<ICountryCode[]>(this.url + 'CountriesCodes.json');
  }

}
