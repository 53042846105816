<cl-login
  [Id]="LoginId"
  [ShouldResolve]="ShouldResolve"
  [ApiUrl]="ApiUrl"
  [PathToRedirect]="PathToRedirect"
  [SesionName]="SesionName"
  [LogoPath]="LogoPath"
  [DotNetApiType]="DotNetApiType"
  [UseReCaptcha] = useReCaptcha
></cl-login>
