import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {Observable, tap} from "rxjs";
import {IUser} from "../interfaces/user";
import {SharedService} from "../shared/shared-service.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly CONTROLLER = 'api/Users'
  constructor(private http: HttpClient, private sharedService: SharedService) { }

  /**
   * Retrieves a list of users.
   *
   * @returns {Observable<ICLResponse<IUser[]>>} An observable containing the response with the list of users.
   */
  Get(): Observable<ICLResponse<IUser[]>>{
    return this.http.get<ICLResponse<IUser[]>>(`${this.CONTROLLER}`, {headers: this.sharedService.DefineDescriptionHeader({OnError: `Error obteniendo usuario`})});
  }

  /**
   * Retrieves a user by its ID.
   *
   * @param {number} Id - The ID of the user to retrieve.
   * @returns {Observable<ICLResponse<IUser>>} An observable containing the response with the user data.
   */
  GetId(Id : number): Observable<ICLResponse<IUser>>{
    return this.http.get<ICLResponse<IUser>>(`${this.CONTROLLER}/${Id}`, { headers: this.sharedService.DefineDescriptionHeader({
        OnError: `Error obteniendo usuario ${Id}`
      })});
  }

  /**
   * Retrieves users based on name and status filters.
   *
   * @param {string} name - The name to filter users by.
   * @param {number} status - The status to filter users by.
   * @returns {Observable<ICLResponse<IUser[]>>} An observable containing the response with the list of users that match the filters.
   */
  GetFilters(name: string, status: number): Observable<ICLResponse<IUser[]>>{
    let params = {
      status: status,
      name: name
    }
    return this.http.get<ICLResponse<IUser[]>>(`${this.CONTROLLER}/Filters`, {params, headers: this.sharedService.DefineDescriptionHeader({
        OnError: `Error obteniendo usuario acorde a filtros`,
      })});
  }

  /**
   * Creates a new user.
   *
   * @param {IUser} user - The user data to create.
   * @returns {Observable<ICLResponse<IUser>>} An observable containing the response with the created user.
   */
  Post(user: IUser): Observable<ICLResponse<IUser>> {
    return this.http.post<ICLResponse<IUser>>(`${this.CONTROLLER}`, user, {headers: this.sharedService.DefineDescriptionHeader({
        OnError: `Error al crear del usuario`,
        OnSuccess: `Creacion de usuario exitosa`
      })});
  }

  /**
   * Updates an existing user.
   *
   * @param {IUser} user - The user data to update.
   * @returns {Observable<ICLResponse<IUser>>} An observable containing the response with the updated user.
   */
  Patch(user: IUser): Observable<ICLResponse<IUser>> {
    return this.http.patch<ICLResponse<IUser>>(`${this.CONTROLLER}`, user, {headers: this.sharedService.DefineDescriptionHeader({
        OnError: `Error al actualizar usuario`,
        OnSuccess: `Actualizacion de usuario exitoso`
      })});
  }

}
