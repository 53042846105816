import {Injectable} from '@angular/core';
import {IMenuItem} from '../interfaces/menu';
import {Subject} from 'rxjs/internal/Subject';
import {Repository, Structures} from '@clavisco/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public MenuItem: Subject<IMenuItem[]>;
  public CurrentPageTitle: Subject<string>;

  private readonly CONTROLLER: string = 'api/Menu';

  constructor (
    private http: HttpClient
  ) {
    this.MenuItem = new Subject<IMenuItem[]>();
    this.CurrentPageTitle = new Subject<string>();
  }

  /**
   * Retrieves the menu items from the server.
   *
   * @returns {Observable<ICLResponse<IMenuItem[]>>} An observable containing the response with the list of menu items.
   */
  Get (): Observable<ICLResponse<IMenuItem[]>> {
    return this.http.get<ICLResponse<IMenuItem[]>>(`${this.CONTROLLER}`);
  }

  /**
   * Sets the current page title based on the given path.
   *
   * @param {string} path - The path used to determine the current page title.
   */
  SetCurrentPageTitle (path: string): void {
    path = path.startsWith('/') ? path.substring(1) : path;
    this.CurrentPageTitle.next(this.GetCurrentViewName(path));
  }

  /**
   * Gets the current view name by finding the description of the menu item matching the given path.
   *
   * @param {string} path - The path used to find the menu item.
   * @returns {string} The description of the menu item matching the given path.
   */
  GetCurrentViewName (path: string): string {
    return this.FindDescriptionRecursively(this.GetLocalMenu(), path);
  }

  /**
   * Recursively searches for a menu item's description based on the given path.
   *
   * @param {IMenuItem[]} menu - The menu items to search through.
   * @param {string} path - The path to match against menu item routes.
   * @returns {string} The description of the menu item matching the path, or an empty string if not found.
   */
  FindDescriptionRecursively (menu: IMenuItem[], path: string): string {
    if (menu != null) {
      for (const node of menu) {
        if (node.Route === path) {
          return node.Description;
        }
        if (node.Nodes && node.Nodes.length > 0) {
          const description: string = this.FindDescriptionRecursively(node.Nodes, path);
          if (description) {
            return description;
          }
        }
      }
    }
    return '';
  }

  /**
   * Retrieves the local menu items from storage.
   *
   * @returns {IMenuItem[]} The list of menu items stored locally.
   */
  GetLocalMenu(): IMenuItem[] {
    return Repository.Behavior.GetStorageObject<IMenuItem[]>('KeyMenu') as IMenuItem[];
  }
}

