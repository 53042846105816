<div>
  <div class="dropzone" style="cursor: pointer;">
    <input  type="file" #fileDropRef id="fileDropRef" [multiple]="MultipleFiles" (change)="OnSelectFiles($event)" onclick="this.value=null;" />
    <img id="file" mat-card-image src="assets/img/cloudUpload.png" alt="Subir Archivo">
    <h2>Arrastra y Suelta</h2>
    <h2>o</h2>
    <label for="FileDropRef">Selecciona un Archivo</label>
  </div>

  <div>
    <mat-list *ngIf="listFiles.length > 0" class="content">
      <mat-list-item *ngFor="let file of listFiles; let i=index">
        <mat-icon style="border-radius: 0%" matListAvatar>description</mat-icon>
        <mat-icon style="border-radius: 0%; cursor: pointer;" (click)="DeleteFile(i)">close</mat-icon>
        <h3 matLine> {{file}} </h3>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>
</div>
